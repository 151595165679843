const STORAGE = "xyz-web";
const BAR_STORAGE = "xyz-web-bard";
const USER_STORAGE = "xyz-web-users";

export function setItem(data) {
  window.localStorage.setItem(STORAGE, JSON.stringify(data));
}

export function getCurrentUser() {
  try {
    let data = window.localStorage.getItem(STORAGE);
    return JSON.parse(data);
  } catch (e) {
    return {};
  }
}

export function clearItem() {
  window.localStorage.setItem(STORAGE, JSON.stringify({}));
  window.localStorage.setItem(BAR_STORAGE, JSON.stringify([]));
  window.localStorage.setItem(USER_STORAGE, JSON.stringify([]));
}

export function setBarsLocal(data) {
  window.localStorage.setItem(BAR_STORAGE, JSON.stringify(data));
}

export function getBarsLocal() {
  try {
    let data = window.localStorage.getItem(BAR_STORAGE);
    return JSON.parse(data);
  } catch (e) {
    return [];
  }
}

export function clearBarsLocal() {
  window.localStorage.setItem(BAR_STORAGE, JSON.stringify([]));
}

export function setUsersLocal(data) {
  window.localStorage.setItem(USER_STORAGE, JSON.stringify(data));
}

export function getUsersLocal() {
  try {
    let data = window.localStorage.getItem(USER_STORAGE);
    return JSON.parse(data);
  } catch (e) {
    return [];
  }
}

export function clearUsersLocal() {
  window.localStorage.setItem(USER_STORAGE, JSON.stringify([]));
}
