import React, {useEffect, useState} from 'react';
import {getCurrentUser} from "../../utils/storage.utils";

function ProtectedRoute(props) {
  const [state, setState] = useState(false);
  useEffect(() => {
    let data = getCurrentUser()
    if (data && data.uid) {
      setState(true);
    } else {
      window.location.pathname = "/login";
    }
  });
  return (
    <div>
      {state && props.children}
    </div>
  );
}

export default ProtectedRoute;
