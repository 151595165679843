import React from "react";
import {Redirect} from "react-router-dom";

// Layout Types
import {DefaultLayout} from "./layouts";

// Route Views
import AddNewPost from "./views/AddNewPost/";
import Login from "./views/Login";
import AllPosts from "./views/AllPosts";
import Logout from "./views/Logout";

export const AUTH = [

  {
    path: "/add-new",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/all-posts",
    layout: DefaultLayout,
    component: AllPosts
  },

];

export const UN_AUTH = [{
  path: "/",
  exact: true,
  component: () => <Redirect to="/login"/>
}, {
  path: "/login",
  exact: true,
  component: Login,
}, {
  path: "/logout",
  component: Logout
},]
