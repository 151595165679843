import React, {useState} from "react";
import {Alert, Button, Card, CardBody, Col, Container, Row} from "shards-react";
import {FaGoogle} from "react-icons/fa";
import "./styles.css"
import * as firebase from "firebase";
import {readUserData} from "../../utils/firebase.utils";
import {setItem} from "../../utils/storage.utils";
import {USER_TYPE} from "../../utils/constants";

firebase.initializeApp({
  apiKey: "AIzaSyB6lFGfIpf-CdUiW9iLVyJW0pJXjRX77HM",
  authDomain: "barglance-82710.firebaseapp.com",
  storageBucket: "barglance-82710.appspot.com",
  projectId: "barglance-82710",
})

const Login = () => {
  const [state, setState] = useState({
    error: false,
  });
  const onGoogleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      let result = await firebase.auth().signInWithPopup(provider);
      const {accessToken} = result.credential;
      const {uid, photoURL, displayName, email} = result.user;
      let user = await readUserData(uid);
      if (user && user.admin) {
        setState({...state, error: false,})
        setItem({
          uid,
          photoURL,
          displayName,
          email,
          accessToken,
          type: USER_TYPE.ADMIN
        });
        window.location.pathname = "/all-posts"
      } else if (user && user.contentEditor) {
        setState({...state, error: false});
        setItem({
          uid,
          photoURL,
          displayName,
          email,
          accessToken,
          type: USER_TYPE.CONTENT_EDITOR
        });
        window.location.pathname = "/all-posts"
      } else {
        setState({...state, error: true,})
      }
    } catch (e) {
      console.log(e);
    }
  }


  const onFacebookLogin = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    try {
      let result = await firebase.auth().signInWithPopup(provider);
      const {accessToken} = result.credential;
      const {uid, photoURL, displayName, email} = result.user;
      let user = await readUserData(uid);
      if (user && user.admin) {
        setState({...state, error: false,})
        setItem({uid, photoURL, displayName, email, accessToken});
      } else {
        setState({...state, error: true,})
      }
    } catch (e) {
      console.log(e);
    }
  }
  return <Container fluid className="main-content-container h-100 px-4 Login">
    <Row noGutters className="h-100">
      <Col lg="3" md="5" className="auth-form mx-auto my-auto">
        <Card>
          <CardBody>
            <img
              width={200}
              className="auth-form__logo d-table mx-auto mb-3"
              src="https://images.squarespace-cdn.com/content/5eafa2e85bb4cf30cf95fe06/1590186606812-3TDY171TFA5TXN7LP4QS/Barglance-logo.png?content-type=image%2Fpng"
              alt="BarGlance Web App Admin Panel"
            />
            <h5 className="auth-form__title text-center mb-4">
              Access Your Account
            </h5>
            {state.error &&
            <Alert theme="danger">USER is not authorized</Alert>}

            {/*<Button pill block size={"lg"} theme="primary" onClick={onFacebookLogin} className="mb-4 mr-1">*/}
            {/*  <FaFacebookF/> Continue with Facebook*/}
            {/*</Button>*/}

            <Button pill
                    block
                    size={"lg"}
                    theme="danger"
                    onClick={onGoogleLogin}
                    className="mb-2 mr-1">
              <FaGoogle/> Continue with Google
            </Button>

          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
};

export default Login;
