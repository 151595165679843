import React, {useRef} from "react";

const CustomFileUpload = props => {
  let uploadInput = useRef(null);

  function onChange() {
    if (uploadInput.files.length > 0) {
      let reader = new FileReader();
      reader.onload = (e) => {
        props.setState({
          ...props.state, image: {
            url: e.target.result,
            data: uploadInput.files[0],
          }
        });
      };
      reader.readAsDataURL(uploadInput.files[0]);
    }
  }

  return <div className="custom-file mb-3">
    <input type="file"
           className="custom-file-input"
           accept="image/*,video/mp4,video/x-m4v,video/*"
           onChange={onChange}
           ref={(ref) => {
             uploadInput = ref;
           }}/>
    <label className="custom-file-label">
      {props.state.image.data ? props.state.image.data.name : "Choose Image/Video"}
    </label>
  </div>
}

export default CustomFileUpload;
