import React from "react";
import {Button, NavItem} from "shards-react";
import {FaPlus, FaSignOutAlt} from "react-icons/all";
import {clearItem} from "../../../../utils/storage.utils";

export default class UserActions extends React.Component {
  render() {
    return (
      <NavItem className="sign-out-container">
        <Button onClick={() => {
          window.location.pathname = "/add-new";
        }}>
          <FaPlus size={16}/>
        </Button>
      </NavItem>
    );
  }
}
