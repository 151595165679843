export function getFinalData(state, image, user) {
  return {
    barId: state.barId,
    image: image,
    description: state.description,
    city: state.city,
    userId: user.uid,
    scheduled: new Date(state.scheduled),
    created: new Date(),
    active: true,
  }
}
