import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, Row} from "shards-react";
import {useHistory} from "react-router-dom";
import "./styles.css";
import PageTitle from "../../components/common/PageTitle";
import {
  createScheduleKey,
  getAllFeedsIndependent,
  markInActive,
  readAllBars,
  readAllUsers
} from "../../utils/firebase.utils";
import {FaArrowDown, FaArrowUp, FaEdit, FaTrash} from "react-icons/all";
import moment from "moment";

const AllPosts = () => {
  const history = useHistory();
  const [state, setState] = useState([]);
  const [bars, setBars] = useState([]);
  const [users, setUsers] = useState([]);

  const [action, setActions] = useState({
    created: true
  });

  useEffect(() => {
    getAllFeedsIndependent(action).then(data => {
      setState(data);
    });
    readAllBars().then((data) => {
      setBars(data)
    });
    readAllUsers().then(data => {
      setUsers(data);
    })
  }, [action]);
  const getImage = (image) => {
    if (image) {
      return <img width={100} src={image} alt="NEWSFEED"/>
    }
  }
  const onDelete = (id) => () => {
    markInActive(id).then(() => {
      getAllFeedsIndependent(action).then(data => {
        setState(data);
      });
    });
  }
  const getBarName = id => {
    let bar = bars.find(a => a.id === id)
    return bar ? bar.barName : null;
  }

  const getUserName = id => {
    let user = users.find(a => a.id === id)
    return user && user.displayName ? user.displayName : id;
  }
  return <Container fluid className="main-content-container px-4 pb-4">
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="All Posts" className="text-sm-left"/>
    </Row>
    <Row>
      <Card className="w-100">
        <CardBody>
          <Row className="post-titles">
            <Col md={2} xs={4}>Name</Col>
            <Col md={2} xs={4}>Image</Col>
            <Col md={3} xs={4}>Description</Col>
            <Col md={2} xs={4}>Creator</Col>
            <Col
              className={"action"}
              md={2}
              xs={4}
              onClick={() => {
                setActions({
                  ...action,
                  created: !action.created,
                })
              }}
            >
              Created {action.created ? <FaArrowDown/> : <FaArrowUp/>}
            </Col>
            <Col md={1} xs={4}>Actions</Col>
          </Row>
          {state.map((a, i) => <Row className="post-tile" key={i}>
            <Col md={2} xs={4}>{getBarName(a.barId) || "BarGlance"}</Col>
            <Col md={2} xs={4}>{getImage(a.image)}</Col>
            <Col md={3} xs={4}>{a.description}</Col>
            <Col md={2} xs={4}>{getUserName(a.userId)}</Col>
            <Col md={1}
                 xs={4}>{moment(a.created.toDate()).utcOffset(-4).format("MM/DD/YY HH:MM")} EST</Col>
            <Col md={2} xs={4}>
              <Button
                onClick={() => history.push("/add-new", a)}><FaEdit/></Button>
              <Button
                theme={"danger"}
                onClick={onDelete(a.id)}><FaTrash/></Button>
            </Col>
          </Row>)}
        </CardBody>
      </Card>
    </Row>
  </Container>
};

export default AllPosts;

