import React, {useEffect} from 'react';
import {clearItem} from "../../utils/storage.utils";

function Logout({}) {
  useEffect(() => {
    clearItem();
    window.location.pathname = "/login";
  })
  return <></>;
}

export default Logout;
