import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";

import {AUTH, UN_AUTH} from "./routes";
import withTracker from "./withTracker";
import "./styles.css"

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles.css";
import ProtectedRoute from "./components/ProtectedRoute";

export default () => (
  <Router>
    <div>
      {UN_AUTH.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={withTracker(props => {
              return (
                <route.component {...props} />
              )
            })}
          />
        );
      })}
      {AUTH.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            component={withTracker(props => {
              return (
                <ProtectedRoute>
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                </ProtectedRoute>
              );
            })}
          />
        );
      })}

    </div>
  </Router>
);
