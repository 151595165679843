import React, {useEffect, useState} from "react";
import {Loader} from "react-full-page-loader-overlay";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormSelect,
  FormTextarea,
  Row
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import {
  postNewsFeed,
  readAllBars,
  uploadFileToStorage
} from "../../utils/firebase.utils";
import CustomFileUpload from "./CustomFileUpload";
import "./styles.css";
import {getFinalData} from "./helper";
import {getCurrentUser} from "../../utils/storage.utils";
import {USER_TYPE} from "../../utils/constants";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const AddNewPost = (props) => {
  const [state, setState] = useState({
    barId: "",
    image: {},
    description: "",
    loading: false,
    newId: "",
  });
  const [bars, setBars] = useState([]);
  const [user, setUser] = useState({});
  useEffect(() => {
    let {state: params} = props.location;
    if (params && params.id) {
      setState({
        ...params,
        image: {url: params.image},
        scheduled: moment((params.scheduled.seconds + params.scheduled.nanoseconds * 10 ** -9) * 1000),
      })
    }
  }, []);

  useEffect(() => {
    readAllBars().then(data => setBars(data))
    setUser(getCurrentUser());
  }, []);
  const handleChange = (key, value) => {
    setState({...state, [key]: value})
  }
  const onSubmit = async event => {
    event.preventDefault();
    setState({...state, loading: true})
    let image = null;
    if (state.image.data) {
      image = await uploadFileToStorage(state.image.data);
    } else if (state.image.url) {
      image = state.image.url;
    }
    let final = getFinalData(state, image, user);
    let id = await postNewsFeed(state.id, final)
    setState({
      barId: "",
      image: "",
      description: "",
      loading: false,
      newId: id,
    })
  }
  const getAllBarCities = bars => {
    const data = [...new Map(bars.map(item => [item["city"], item])).values()]
    return data.map(({id, city}, i) => <option key={i}
                                               value={city}>{city}</option>)
  }
  return <Container fluid className="main-content-container px-4 pb-4">
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4"
                 title={state.id ? "Edit Post" : "Add New Post"}
                 className="text-sm-left"/>
    </Row>
    <Loader show={state.loading}/>
    <Row>
      <Col md={8} sm={12}>
        <Card>
          <CardBody>
            {state.newId &&
            <Alert
              theme={"info"}>NewsFeed {state.id ? "updated" : "added"}, {state.newId}</Alert>}
            <form onSubmit={onSubmit}>
              <Row>
                {user.type === USER_TYPE.ADMIN && <Col md={6} sm={12}>
                  <label className="text-muted d-block mb-2">Bar Name</label>
                  <FormSelect
                    disabled={!!state.id}
                    value={state.barId}
                    onChange={(event) => handleChange("barId", event.target.value)}>
                    <option value="">BarGlance</option>
                    {bars.length > 0 && bars.map(({id, barName}, i) => {
                      return <option key={i} value={id}>{barName}</option>
                    })}
                  </FormSelect>
                </Col>}
                <Col md={6} sm={12}>
                  <label className="text-muted d-block mb-2">Location</label>
                  <FormSelect
                    value={state.city}
                    onChange={(event) => handleChange("city", event.target.value)}>
                    <option value="">Select Location</option>
                    {bars.length > 0 && getAllBarCities(bars)}
                  </FormSelect>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12} style={{marginTop: 15}}>
                  <label className="text-muted d-block mb-2">Image/Video
                    (optional)</label>
                  <CustomFileUpload state={state} setState={setState}/>
                </Col>
                <Col md={6} sm={12} style={{marginTop: 15}}>
                  <label className="text-muted d-block mb-2">Scheduled</label>
                  <Datetime
                    onChange={(value) => handleChange("scheduled", new Date(value.toString()))}
                    value={state.scheduled}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={8} sm={12}>
                  <label className="text-muted d-block mb-2">Description</label>
                  <FormTextarea rows={"15"}
                                value={state.description}
                                onChange={(event) => handleChange("description", event.target.value)}
                                required/>
                </Col>

                <Col md={4} sm={12}>
                  <label className="text-muted d-block mb-2">
                    Image Preview
                  </label>
                  <img
                    className="image_preview"
                    src={state.image.url}
                    alt={""}/>
                </Col>
                <Col md={12} sm={12}>
                  <Button block className="mt-3"
                          theme="success">{state.id ? "Update" : "Add"}</Button>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
}
export default AddNewPost;
