import * as firebase from "firebase";
import { v4 as uuidv4 } from 'uuid';
import {
  getBarsLocal,
  getCurrentUser,
  getUsersLocal,
  setBarsLocal,
  setUsersLocal
} from "./storage.utils";
import { USER_TYPE } from "./constants";


export async function readUserData(userId) {
  let data = await firebase.firestore().collection("users").doc(userId).get()
  return data.data();
}

export async function readAllBars() {
  let existing = getBarsLocal();
  if (!existing || existing.length || Object.keys(existing).length === 0) {
    let snapshot = await firebase.firestore().collection("bars").get()
    let newData = snapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() }
    });
    setBarsLocal(newData);
    return newData;
  } else {
    return existing;
  }
}

export async function readAllUsers() {
  let existing = getUsersLocal();
  if (!existing || existing.length || Object.keys(existing).length === 0) {
    let snapshot = await firebase.firestore().collection("users").get()
    let newData = snapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() }
    });
    setUsersLocal(newData);
    return newData;
  } else {
    return existing;
  }
}


export async function getAllFeedsIndependent(action) {
  let { type, uid } = getCurrentUser();
  if (type === USER_TYPE.ADMIN) {
    return await readAllFeedsAdmin(action);
  } else if (type === USER_TYPE.CONTENT_EDITOR) {
    return await readAllFeedsCE(action, uid);
  }
}

export async function readAllFeedsAdmin({ created = true }) {
  let now = new Date()
  let last30days = new Date(now.setDate(now.getDate() - 30)).getTime() / 1000
  let snapshot = await firebase.firestore().collection("newsfeed").orderBy("created", created ? "desc" : "asc").get()
  return snapshot.docs.map(doc => {
    return { id: doc.id, ...doc.data() }
  }).filter((a) => a.active && a.created.seconds >= last30days);
}

export async function readAllFeedsCE({ created = true }, uid) {
  let snapshot = await firebase.firestore().collection("newsfeed").orderBy("created", created ? "desc" : "asc").get()
  return snapshot.docs.map(doc => {
    return { id: doc.id, ...doc.data() }
  }).filter((a) => a.active && a.userId === uid);
}

export function uploadFileToStorage(image) {
  return new Promise((resolve, reject) => {
    let uploadTask = firebase.storage().ref(`news_feed_images/${image.name}`).put(image);
    uploadTask.on(
      'state_changed',
      function (snapshot) {
        const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
        console.log('Upload is ' + progress + '% done');
      },
      function (error) {
        reject(error);
        console.error(error);
      },
      function () {
        firebase.storage().ref('news_feed_images').child(image.name).getDownloadURL()
          .then(fireBaseUrl => {
            resolve(fireBaseUrl);
          })
      }
    );
  })
}

export async function postNewsFeed(id, data) {
  if (id) {
    return await updateNewsFeed(id, data);
  } else {
    return await addNewNewsFeed(data);
  }
}


async function addNewNewsFeed(data) {
  let id = uuidv4()
  await firebase.firestore().collection("newsfeed").doc(id).set({
    ...data,
  })
  return id;
}

async function updateNewsFeed(id, data) {
  await firebase.firestore().collection("newsfeed").doc(id).update({
    ...data,
  })
  return id;
}


export async function markInActive(id) {
  try {
    await firebase.firestore().collection("newsfeed").doc(id).update({ active: false })
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// export async function createScheduleKey() {
//   try {
//     let data = await firebase.firestore().collection("newsfeed").get()
//     data.docs.map(async a => {
//       let {created, scheduled} = a.data();
//       if (scheduled === undefined) {
//         await firebase.firestore().collection("newsfeed").doc(a.id).update({scheduled: new Date(created.toDate())})
//       }
//     })
//
//   } catch (e) {
//     console.error(e);
//   }
// }
