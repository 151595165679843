import React, {Component} from "react";

const withTracker = (WrappedComponent) => {
  return class extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default withTracker;
