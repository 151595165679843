import {FaNewspaper, FaPlus, FaSignOutAlt} from "react-icons/all";
import React from "react";

export default function () {
  return [
    {
      title: "News Feeds",
      htmlBefore: <FaNewspaper size={20}/>,
      to: "/all-posts",
    },
    {
      title: "Add New Feeds",
      htmlBefore: <FaPlus size={20}/>,
      to: "/add-new",
    },
    {
      title: "Logout",
      htmlBefore: <FaSignOutAlt size={20}/>,
      to: "/logout",
    },
  ];
}
